import React, {useState} from "react"
import { Link} from 'gatsby'
import Socials from '../socials'
import logo from '../../images/logo.svg'
import hamburgerIcon from './hamburgericon.svg'

const menuItems = [{
  text: 'About Us',
  link: '/#about',
},{
  text: 'Products',
  link: '/#products',
},{
  text: 'News',
  //link: '/news',
  link: 'https://nakji.medium.com/'
},{
  text: 'Jobs',
  external: 'https://jobs.lever.co/Blep',
}]

const Header = ({showBg}) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  return (
    <header className={`container xl:max-w-[1440px] mx-auto px-4 xl:px-20 py-[30px] ${showBg ? "bg-[#212927]" : ""}`}>
      <nav className="relative flex flex-col justify-between lg:flex-row lg:items-center">

        <div className="z-10 flex items-center justify-between lg:relative">
          {/*<!-- Logo -->*/}
          <Link to="/"><img src={logo} alt="logo"
            className="h-6 xl:h-[30px]" /></Link>

          {/*<!-- Hamburger Menu -->*/}
          <button className="lg:hidden" onClick={() => {setNavbarOpen(!navbarOpen)}}>
            <img src={hamburgerIcon} alt="menu-icon" />
          </button>
        </div>

        <Socials className="hidden lg:flex items-center space-x-[10px] lg:relative z-10" />

        {/*<!-- Menus -->*/}
        <ul 
          className={`${navbarOpen ? 'flex' : 'hidden lg:flex'} flex-col mt-5 lg:flex-row lg:items-center lg:justify-center space-y-3 lg:space-y-0 lg:space-x-5 lg:absolute lg:inset-x-0 lg:z-0 lg:mt-0 uppercase text-white text-base`}>
          {menuItems.map(item => (
            <li key={item.text}>
              {item.external ?
                <a href={item.external}>{item.text}</a>
                : <Link to={item.link}>{item.text}</Link>
              }
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header
