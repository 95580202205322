import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Socials = ({className}) => {
  const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          social {
            twitter
            linkedin
          }
        }
      }
    }
    `
  )

  return (
    <div className={className}>
      <a href={site.siteMetadata.social.linkedin}>
        <LinkedinIcon />
      </a>
      <a href={`https://twitter.com/${site.siteMetadata.social.twitter}`}>
        <TwitterIcon />
      </a>
    </div>
  )
}

export default Socials

const LinkedinIcon = () => (
  <svg data-name="Group 84" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
    viewBox="0 0 24 24">
    <path data-name="Path 851" d="M0 0h24v24H0Z" fill="none" />
    <rect data-name="Rectangle 39" width="16" height="16" rx="2" transform="translate(4 4)"
      fill="none" stroke="#526782" strokeLinecap="round" strokeLinejoin="round"
      strokeWidth="1.5" />
    <path data-name="Line 46" fill="none" stroke="#526782" strokeLinecap="round"
      strokeLinejoin="round" strokeWidth="1.5" d="M8 11v5" />
    <path data-name="Line 47" fill="none" stroke="#526782" strokeLinecap="round"
      strokeLinejoin="round" strokeWidth="1.5" d="M8 8v.01" />
    <path data-name="Line 48" fill="none" stroke="#526782" strokeLinecap="round"
      strokeLinejoin="round" strokeWidth="1.5" d="M12 16v-5" />
    <path data-name="Path 852" d="M16 16v-3a2 2 0 0 0-4 0" fill="none" stroke="#526782"
      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
  </svg>
)

const TwitterIcon = () => (
  <svg id="Group_95" data-name="Group 95" xmlns="http://www.w3.org/2000/svg" width="24"
    height="24" viewBox="0 0 24 24">
    <path id="Path_6" data-name="Path 6" d="M0,0H24V24H0Z" fill="none" />
    <path id="Path_7" data-name="Path 7"
      d="M22,4.01A17.943,17.943,0,0,1,19,5a3.789,3.789,0,0,0-4.38-.737A3.933,3.933,0,0,0,12,8V9A9.392,9.392,0,0,1,4,5S-.182,12.433,8,16a9.856,9.856,0,0,1-6,2,13.425,13.425,0,0,0,10.034,1.517,11,11,0,0,0,7.651-7.742,13.84,13.84,0,0,0,.5-3.753c0-.249,1.51-2.772,1.818-4.013Z"
      fill="none" stroke="#526782" strokeLinecap="round" strokeLinejoin="round"
      strokeWidth="1.5" />
  </svg>
)
