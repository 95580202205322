import * as React from "react"
import {Link} from 'gatsby'

import Socials from '../socials'
import logo from '../../images/logo.svg'
import footerBg from './footer-bg.svg'

const Footer = () => {
  return (
    <footer className="relative w-full overflow-hidden bg-blue-90">
      <img src={footerBg} alt="footer-bg" className="absolute top-0 right-0 z-0"/>
      <div className="container xl:max-w-[1440px] mx-auto px-4 xl:px-20 py-10 lg:py-20 relative">
        <div className="mb-[198px]">
          <Link to="/" className="inline-block mb-10"><img src={logo} alt=""
            className="h-[30px]" /></Link>
          <p className="text-lg text-white-20">Building the future of Web 3.0</p>
        </div>
        <div className="flex flex-col items-center justify-between lg:flex-row lg:border-t border-blue-10 lg:pt-10">
          <div
            className="order-2 w-full pt-10 text-center border-t lg:text-left lg:border-t-0 border-blue-10 lg:pt-0 lg:order-1 lg:w-auto">
            <p className="text-xs font-medium tracking-[0.17em] uppercase text-blue-10">Copyright © {new Date().getFullYear()} Blep
              Technologies</p>
            <div className="flex items-center justify-center lg:justify-start space-x-3">
              <span className="text-xs font-medium tracking-[0.17em] uppercase text-blue-10">All rights
                reserved</span>
              <span
                className="pointer-events-none ext-xs font-medium tracking-[0.17em] uppercase text-blue-10">|</span>
              <Link to="/" className="text-xs font-medium tracking-[0.17em] uppercase text-blue-10">Privacy</Link>
            </div>
          </div>

          <Socials className="order-1 lg:order-2 pb-10 lg:pb-0 flex items-center justify-end space-x-[10px] w-full lg:w-auto" />

        </div>
      </div>
    </footer>
  )
}

export default Footer
