import * as React from "react"

import Header from './header'
import Footer from './footer'

const Layout = ({ location, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header = null

  if (isRootPath) {
    header = null
  } else {
    header = (
      <Header showBg />
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      {header}
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
